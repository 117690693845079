<template>
  <div class="demoStudy">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>项目学习</div>
      <i></i>
    </div>
    <ul class="studyMain">
      <li @click="goNext(item)" v-for="(item,index) in demoList" :key="index">
        <div>
          <img src="../../assets/img/demoStudyImg.png" alt="">
        </div>
        <p>{{item.Title}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      demoList: [],
      StudentGroupID:"",//分组ID
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/LearningProject/GetList?studentGroupID='+ this.StudentGroupID, "").then(res => {
        console.log(JSON.parse(res.data));
        var data = JSON.parse(res.data);
        if (data.code == 200) {
          this.demoList = data.data;
        }
      });
    },
    // 点击头部左上角返回按钮
    back() {
      this.$router.push({ name: "home" });
    },
    // 点击项目1进入项目
    goNext(item) {
      this.cookies.kkSet("projectID", item.ID);
      this.cookies.kkSet("demoName", item.Title)
      this.$router.push({ name: "demoIndex" });
    }
  },
  mounted() {
    this.StudentGroupID=this.cookies.kkGet("StudentGroupID");
    this.initData();
  },
}
</script>

<style>
.demoStudy img {
  width: 100%;
  height: 100%;
}
.demoStudy .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}
.demoStudy .header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.demoStudy .studyMain {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 44px;
  padding-top: 15px;
  justify-content: space-between;
  padding: 0 10px;
}
.demoStudy .studyMain li {
  width: 160px;
  height: 160px;
  font-size: 16px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
  margin: 0 5px;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.demoStudy .studyMain li div {
  width: 100%;
  height: 90px;
}
.demoStudy .studyMain li p {
  margin-top: 10px;
}
</style>